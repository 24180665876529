@use "sass:meta" as ---eyygoxs6v1;@use 'sass:math';

.company-members {
  display: block;
  float: right;

  span {
    color: $dark-gray;
    font-size: rem-calc(12);
    display: inline-block;
    vertical-align: bottom;
    line-height: 40px;
  }

  a {
    display: inline-block;
    margin-left: .5rem;
  }

  svg,
  img {
    height: 40px;
    max-width: 80px;
    vertical-align: top !important;
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3879");