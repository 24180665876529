@use "sass:meta" as ---eyygoxs6v1;@use 'sass:math';

.facetnav {
  @include clearfix;

  clear: both;
  padding: math.div($global-padding, 2) 0;
  border-bottom: dashed 1px $medium-gray;
  font-size: rem-calc(13);

  @include small-padding(5px 15px);

  .rubs {
    margin: 1px 0 4px !important;
  }

  .title {
    float: left;
    width: 120px;
    font-weight: $global-weight-bold;
    color: $light-black;
  }

  ul {
    margin: 0 0 math.div($global-margin, 2) 120px;
    list-style: none;
  }

  li {
    float: left;
    margin: 0 math.div($global-margin, 2) 0 0;

    small,
    span {
      padding-left: math.div($global-padding, 4);
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3831");