@use "sass:meta" as ---eyygoxs6v1;#brandingspot {
  @include breakpoint(medium down) {
    padding-top: 0 !important;
  }

  @media print {
    padding-top: 0 !important;
  }

  video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 1080px;
    max-width: 1920px;
    object-fit: cover;
  }
}

#adplace73 {
  margin-top: $global-margin;
  margin-bottom: $global-margin;
  max-height: 70px;
  overflow: hidden;
  text-align: center;
}

iframe.banner {
  border: none;
  overflow: hidden;
}

#bs3,
#bs11,
#bs22,
#bs51,
#bs52,
#bs53,
#bs70,
#bs71,
#bs73 {
  line-height: 0;
}

#bs71 {
  height: 90px;
}

#bs73 {
  @include grid-column-margin;

  display: flex;
  justify-content: center;
}

#bs1,
#bs71 {
  iframe {
    margin-bottom: 0;
  }
}

#bs22 {
  @include grid-column-margin;

  iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3817");