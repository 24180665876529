@use "sass:meta" as ---eyygoxs6v1;.brand-list {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: $global-margin;
  margin-top: $global-margin;

  &::after {
    content: '';
    flex-grow: 1;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &__letters {
    display: flex;
    flex-flow: row wrap;
    margin-top: $global-margin;
    margin-bottom: $global-margin;

    @include breakpoint(small only) {
      justify-content: center;
    }

    a {
      padding: 0 .5rem;

      @include breakpoint(small only) {
        padding: 10px;
      }
    }
  }

  &__letter {
    background-color: $light-gray;
    font-weight: $global-weight-bold;
    margin-bottom: $global-margin;
    margin-top: $global-margin;
    padding: .25rem .5rem;
  }

  &__logo {
    align-items: center;
    aspect-ratio: 1;
    display: flex;
    line-height: 0;
    margin: .5rem;

    svg {
      height: 100px;
      width: 100px;
    }
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3821");