@use "sass:meta" as ---eyygoxs6v1;@use 'sass:math';

.color-sell {
  color: $sell !important;
}

.color-buy {
  color: $buy !important;
}

.color-service {
  color: $service !important;
}

.color-exchange {
  color: $exchange !important;
}

.color-resume {
  color: $resume !important;
}

.color-vacancy {
  color: $vacancy !important;
}

.color-misc {
  color: $misc !important;
}

.color-black {
  @include color-mixin($black);
}

.color-light-black {
  @include color-mixin($light-black);
}

.color-green {
  @include color-mixin(#690);
}

.color-grey {
  @include color-mixin(get-color(secondary));
}

.color-white {
  color: #fff;
}

.color-cap {
  @include color-mixin(#ffa533);
}

.high {
  @include color-mixin(get-color(alert));

  a {
    @include color-mixin(get-color(alert));

    text-decoration: underline;
  }
}

.anchor-color-hover {
  &:hover {
    color: $anchor-color-hover;
  }
}

.background-color-white {
  background-color: $white;
}

.background-alice-blue {
  background-color: $alice-blue;
}

.background-san-marino {
  background-color: map_get($foundation-palette, san-marino);
}

.background-light-gray {
  background-color: $light-gray;
}

.background-medium-gray {
  background-color: $medium-gray;
}

.background-dark-gray {
  background-color: $dark-gray;
}

.border-bottom-blue {
  border-bottom: solid 1px $alice-blue;
}

.stars1,
.stars2,
.stars3,
.stars4,
.stars5 {
  color: #fc3;
}

// text

.text-sm {
  font-size: 14px;
  line-height: 1.25rem;
}

.gl {
  font-size: $small-font-size;
  padding-top: 4px;
  color: $small-font-color;
}

.online {
  color: #390 !important;
  padding-left: 5px;
}

.offline {
  color: #930;
  padding-left: 5px;
}

.ltl {
  font-size: $small-font-size;
  line-height: 1.275em;
}

.strong {
  font-weight: $global-weight-bold;
}

.nowrap {
  white-space: nowrap;
}

.medium-nowrap {
  @include breakpoint(medium) {
    white-space: nowrap;
  }
}

.text-decoration-none {
  text-decoration: none !important;
}

.word-break-all {
  word-break: break-all;
}

.font-normal {
  font-weight: $global-weight-normal;
}

// margin

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.small-margin-top {
  @include breakpoint(small only) {
    margin-top: $global-margin;
  }
}

.small-only-margin-top-20 {
  @include breakpoint(small only) {
    margin-top: 20px;
  }
}

// padding

.small-padding {
  @include small-padding;

  @include breakpoint(small only) {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3 {
      margin: 15px -15px !important;
    }
  }
}

.padding-5px {
  padding: 5px;
}

.padding-bottom {
  @include xy-cell-gutters($gutter-type: padding, $gutter-position: bottom, $breakpoint: 'auto');
}

// misc

.medium-float-left {
  @include breakpoint(medium) {
    float: left;
  }
}

.medium-float-right {
  @include breakpoint(medium) {
    float: right;
  }
}

.display-inline-flex {
  display: inline-flex;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.vertical-middle {
  vertical-align: middle;
}

.width-10rem {
  width: 10rem;
}

.width-100p {
  width: 100%;
}

.height-100p {
  height: 100%;
}

span.required {
  color: $alert-color;
  padding-left: 2px;
  font-weight: $global-weight-bold;
  vertical-align: top;
}

.scrolled-width {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  text-align: center;

  img {
    max-width: inherit;
  }
}

.read-more a,
.read-less a,
.inline-editor {
  border-bottom: 1px dashed;
  color: $black;
  cursor: pointer;
}

.inline-editor {
  padding-bottom: math.div($global-padding, 2);
  margin-bottom: math.div($global-margin, 2);

  form,
  textarea {
    margin-bottom: math.div($global-margin, 2);
  }
}

a.headerlink {
  color: $alert-color;
  padding: 0 .5rem 0 0;
  visibility: hidden;

  *:hover > & {
    visibility: visible;
  }
}

a.online {
  text-decoration: underline !important;
}

.user_name {
  padding-left: $global-padding;
  background-image: url('svg/user-name.svg');
  background-repeat: no-repeat;
}

.company_name {
  padding-left: $global-padding;
  background-image: url('svg/company-name.svg');
  background-repeat: no-repeat;
}

.ajax-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.htmx-indicator {
  display: none;
  fill: map_get($foundation-palette, primary);

  &.htmx-request {
    display: inline;
  }

  .save-spinner {
    transform-origin: center;
    animation: save-spinner .75s infinite linear;
  }
}

.bar-loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
}

@keyframes save-spinner {
  100% {
    transform: rotate(360deg);
  }
}

#loading {
  display: none;
  width: 43px;
  height: 11px;
  margin: 0 auto;
  background: transparent url('img/loading.gif') no-repeat;

  &.video-encoding {
    display: block;
    width: 120px;
    height: 60px;
    background-position: 50% 50%;
  }
}

.hr {
  margin: $global-margin auto;
}

#map {
  margin-bottom: $global-margin;

  .filial_title {
    font-size: rem-calc(13);
    font-weight: $global-weight-normal;
  }
}

.pointer {
  cursor: pointer;
}

.dropdown-pane {
  box-shadow: 0 13px 56px rgb(174 174 174);

  @include breakpoint(small only) {
    display: none !important;
  }
}

.placeholder {
  background: white url('svg/placeholder.svg') no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
}

.small-table {
  @include breakpoint(small only) {
    & > div {
      display: table;
      margin: 0 auto;
    }

    @include table-scroll;
  }
}

.small-l {
  @include small-padding(0 10px);
  @include small-l;
}

.position-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}

.size-18 {
  width: 18px;
  height: 18px;
  overflow: hidden;
}

.size-42 {
  width: 42px;
  height: 42px;
  overflow: hidden;
}

.size-54 {
  width: 54px;
  height: 54px;
  overflow: hidden;
}

.size-130 {
  width: 130px;
  height: 130px;
  overflow: hidden;
}

.tooltip-fav {
  background-color: #f0f0f0;
  font-size: rem-calc(10);
  color: $light-black;
  border: 1px solid #b1b1b1;
  border-radius: $global-radius;

  &::before {
    border-color: transparent transparent rgb(134 134 134 / 47%) !important;
  }
}

.tooltip-ads {
  background-color: $white;
  font-size: rem-calc(12);
  color: $light-black;
  border: 1px solid $dark-gray;
  border-radius: $global-radius;
  max-width: 100%;
  width: auto;

  table {
    border: 1px solid #0a0a0a;
    border-collapse: collapse;

    //width: 200px;

    th {
      font-weight: $global-weight-normal;
      padding: 4px;
      white-space: nowrap;
    }

    td {
      padding: 4px;

      span {
        font-size: rem-calc(11);
      }

      &:last-child {
        text-align: center;
      }
    }
  }

  &::before {
    border-color: transparent transparent rgb(134 134 134 / 47%) !important;
  }

  &-wrap {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dashed;
  }
}

a.hashtag + a.hashtag {
  margin-left: rem-calc(4);
}

.callout {
  .button {
    margin-bottom: 0;
  }
}

.delimiter-block {
  display: flex;
  flex-flow: row nowrap;
  font-size: rem-calc(20);
  gap: $global-margin;
  justify-content: center;
  margin-bottom: $global-margin;
  margin-top: $global-margin;
}

.row-inline-img {
  flex-wrap: nowrap;
  gap: 1rem;

  >.cell {
    flex-shrink: 1;
  }
}

.title-with-icon {
  display: flex;
  justify-content: space-between;
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3820");